;
(function($){

// Docu Ready
$(document).ready(function() {
  function bgIsInViewport(node) {
    var rect = node.getBoundingClientRect()
    return (
      (rect.height > 0 || rect.width > 0) &&
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  $(window).scroll(function() {
    var scrolled = $(window).scrollTop()
    $('.bg-parallax').each(function(index, element) {
      var initY = $(this).offset().top;
      var height = $(this).height();
      var endY  = initY + $(this).height();


      // Check if the element is in the viewport.
      var visible = bgIsInViewport(this);
      if(visible) {
        var diff = scrolled - initY;
        var ratio = Math.round((diff / height) * 100);
        var posY = parseInt(( ratio * 0.6));
        $(this).css('background-position','center ' + posY + 'px');
      }
    })
  });

  //END ready
});

// Window Resize
$(window).resize(function(){

// End window Resize
});

// Doc scroll
$(document).scroll(function() {
  if ($(document).scrollTop() >= 80) {
    $('.navbar-header').removeClass('at-top');
  } else {
    $('.navbar-header').addClass('at-top');
  }
  //END ready
});


//
})(jQuery);